import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout.js'
import {Container, Row, Col} from 'react-bootstrap';
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

class NoPageFound extends React.Component {
  render() {
    const pageData = get(this, 'props.data.contentfulPage')
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'404 | Adam Doe'} />

          <div className="home">
            <Container>
              <Row>
                <Col xs={12} md={5}>
                  <img
                    src={pageData.image.file.url}
                    alt="Web Developer"
                    className="w-100"
                  />
                </Col>
                <Col xs={12} md={7}>
                  <div className="text-area">
                    <h1>{pageData.heading}</h1>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NoPageFound

export const pageQuery = graphql`
  query pageNotFoundQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
        }
      }
    }
    contentfulPage(slug: { eq: "/404" }, image: { file: { url: {} } }) {
      id
      slug
      heading
      image {
        id
        file {
          url
        }
      }
      body {
        raw
      }
    }
  }
`
